<template>
  <div>
    <el-card>
      <el-row>
        <el-col :span="4" style="display: flex">
          <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-arrow-left" @click="$router.go(-1)">返回</el-button>
          <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-plus" @click="addClick()">添加</el-button>
        </el-col>
        <el-col :span="13" style="display: flex">
          <el-input placeholder="请输入卡号" size="medium" style="max-width: 200px" v-model="params.cardKey" clearable @clear="getData">
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
          <el-input placeholder="请输入卡密" class="ml-10" style="max-width: 200px" size="medium" v-model="params.cardCode" clearable @clear="getData">
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
          <el-select size="medium" class="ml-10" collapse-tags v-model="params.status" :props="props" @change="getList" clearable>
            <el-option value="1" label="未使用"></el-option>
            <el-option value="2" label="已使用"></el-option>
          </el-select>
        </el-col>
        <el-col :span="7">
          <div style="display: flex">
            <el-link target="_blank" href="./kaJunTemplate.xlsx" type="primary">下载模板</el-link>
            <el-upload
              action="/hiq-admin/saas/card/cardImport"
              class="ml-10"
              accept=".xlsx, .xls"
              ref="upload"
              :data="uploadData"
              :on-success="onSuccess"
              limit="1"
              show-file-list="false"
            >
              <el-button class="demo-input-label" type="primary" size="medium" style="" icon="el-icon-upload2">点击上传</el-button>
            </el-upload>
            <el-button type="primary" size="medium" class="demo-input-label" icon="el-icon-download" @click="exportFile">导出</el-button>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <div>
      <el-table :data="records" border style="width: 98%" :height="innerHeight">
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="name" label="关联奖品" min-width="150" :show-overflow-tooltip="true"></el-table-column>

        <el-table-column prop="cardKey" label="卡号" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="cardCode" label="卡密" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="status" label="使用状态" min-width="150" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? '未使用' : scope.row.status == 2 ? '已使用' : '' }}
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" label="更新时间" min-width="150" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column label="操作" min-width="300">
          <template slot-scope="scope">
            <el-button type="primary" icon="el-icon-edit" size="mini" @click="updateClick(scope.row)">编辑</el-button>
            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <pagination :pagingParams="params" :total="total" @pagingSizeChange="handleSizeChange" @pagingNumChange="handleCurrentChange"></pagination>
    </div>

    <!--  编辑奖品/ 添加奖品-->
    <el-dialog :title="rowData ? '编辑卡券' : '添加卡券'" :visible.sync="addKaJun" v-if="addKaJun" width="50%" @close="addKaJun = false">
      <addKaJun
        :rowData="rowData"
        @quXiaoClick="addKaJun = false"
        @diaQueDingClick="diaQueDingClick"
        :name="name"
        :prizeId="params.prizeId"
        :tenantName="params.tenantName"
      ></addKaJun>
    </el-dialog>
  </div>
</template>
<script>
import pagination from '@/components/common/pagination/Index.vue'
import { commonMixin } from '@/utils/mixin/index.js'
import addKaJun from '@/components/addKaJun.vue'

export default {
  components: { pagination, addKaJun },
  mixins: [commonMixin],
  data() {
    return {
      addKaJun: false, // 新增奖品
      params: {
        pageNum: 1,
        pageSize: 10,
        prizeId: '',
        tenantName: '',
        cardKey: '',
        cardCode: '',
        status: '',
      },
      records: [],
      total: 0,
      rowData: '',
      name: '',
      uploadData: {},
    }
  },

  mounted() {
    let { id, tenantName, name } = this.$route.query
    this.params.prizeId = id
    this.params.tenantName = tenantName
    this.uploadData = { prizeId: id, tenantName: tenantName }
    this.name = name
    this.getData()
  },
  methods: {
    // 获取列表
    async getData() {
      const res = await this.$API.card.get(this.params)
      if (res.status != 200) return this.$message.error(res.msg)
      let { records, total } = res.data
      records.forEach((t) => (t.name = this.name))
      this.records = records
      this.total = parseInt(total)
    },
    getList() {
      this.params.pageNum = 1
      this.getData()
    },
    // 添加
    addClick() {
      this.rowData = ''
      this.addKaJun = true
    },
    // 编辑
    updateClick(row) {
      this.rowData = row
      this.addKaJun = true
    },
    // 弹框上 确定 click
    async diaQueDingClick() {
      this.addKaJun = false
      this.getData()
    },

    //  删除
    deleteItem(row) {
      const params = {
        id: row.id,
        tenantName: this.params.tenantName,
      }
      this.$confirm('此操作将永久删除该奖品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async (res) => {
          if (res == 'confirm') {
            const res = await this.$API.card.delete(params)
            if (res.status != 200) return this.$message.error(res.msg)
            this.$message.success(res.data)
            this.params.pageNum = 1
            this.getData()
          } else {
            // resolve(true)
          }
        })
        .catch((err) => err)
    },

    downloadTemplate() {},
    onSuccess(res) {
      this.importDataText = '导入数据'
      this.importDataIcon = 'el-icon-upload2'
      if (res.status == 200) {
        this.$message.success(res.msg)
        this.$refs.upload.clearFiles()
        this.getData()
      } else {
        this.$message.error(res.msg)
        this.$refs.upload.clearFiles()
      }
    },
    async exportFile() {
      const res = await this.$API.card.ExportFile({
        prizeId: this.params.prizeId,
        tenantName: this.params.tenantName,
      })
      const link = document.createElement('a')
      let blob = new Blob([res], { type: 'application/vnd.ms-excel' })
      link.style.display = 'none'
      link.href = URL.createObjectURL(blob)
      link.download = '卡券.xlsx'
      document.body.appendChild(link)
      link.click()
    },
  },
}
</script>

<style lang="scss" scoped>
.topDiv {
  display: flex;
  .el-input {
    width: 200px;
    margin-right: 20px;
  }
}

.pagination {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
}
.el-breadcrumb {
  margin-bottom: 20px;
}
.demo-input-label {
  display: inline-block;
  width: 130px;
  margin-right: 10px;
  margin-left: 0;
}
::v-deep.el-tree-node {
  .is-leaf + .el-checkbox .el-checkbox__inner {
    display: inline-block;
  }
  .el-checkbox .el-checkbox__inner {
    display: none;
  }
}
.el-pagination {
  width: 1000px;
  margin: 20px auto;
}
.el-tag {
  margin: 7px;
}
</style>
